import NewsPageModel from '../../NewsPage/Models/NewsPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { H1 } from '../../Atoms/Typography/Headings/Heading';
import { styled } from '../../stitches.config';

function NewsPage() {
  const { heading, introText, bodyText, publishDate, tags } =
    useCurrentPage<NewsPageModel>();
  const { 'newsPage/tags': tagsTranslation } = useTranslationData();
  return (
    <main>
      <Root>
        <ContentContainer>
          <H1 css={HeadingStyle}>{heading}</H1>
          <DateAndTagsDiv>
            <DateSpan>{publishDate}</DateSpan>
            {!!tags.length && (
              <TagsWrapper>
                <Tags>{tagsTranslation + ':'}&nbsp;</Tags>
                {tags.map((tag, index) => (
                  <p key={tag}>
                    {tag}
                    {index !== tags.length - 1 && ','}
                    &nbsp;
                  </p>
                ))}
              </TagsWrapper>
            )}
          </DateAndTagsDiv>
          <Intro>{introText}</Intro>
          <BodyText
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        </ContentContainer>
      </Root>
    </main>
  );
}

const Root = styled('div', {
  p: 4,
  '@mediaMinLarge': {
    py: 16,
  },
});

const ContentContainer = styled('div', {
  maxW: '$maxWidthSmall',
  mx: 'auto',
});

const HeadingStyle = {
  fs: 14,
  fontWeight: '$fw300',
  lineHeight: '$lh125',
  ls: '$ls163',
  mb: 2,
  '@mediaMinLarge': {
    fs: 24,
    ls: '$ls3',
    mb: 4,
  },
};

const Intro = styled('p', {
  fs: 8,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls113',
  mb: 6,
  '@mediaMinLarge': {
    fs: 9,
  },
});

const DateAndTagsDiv = styled('div', {
  display: 'flex',
  fs: 5,
  ls: '$ls06',
  justifyContent: 'space-between',
  mb: 3,
  '@mediaMinSmall': {
    fs: 6,
    mb: 4,
  },
  '@mediaMinLarge': {
    fs: 7,
  },
});

const BodyText = styled('div', {
  lineHeight: '$lh1625',
  ls: '$ls06',
});

const TagsWrapper = styled('div', {
  display: 'flex',
});

const DateSpan = styled('p', {
  opacity: 0.7,
});

const Tags = styled('p', {
  fontWeight: '$fw700',
});

export default NewsPage;
